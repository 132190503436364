import React from 'react';
import { Skill } from '../types/ProfileTypes';

interface SkillsProps {
  data: Skill[] | undefined;
}

const Skills: React.FC<SkillsProps> = ({ data }) => {
  if (!data || data.length === 0) return null;

  return (
    <section className="bg-gray-800 shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4 text-white">Skills</h2>
      <div className="flex flex-wrap gap-2">
        {data.map((skill, index) => (
          <div key={index} className="bg-gray-700 text-gray-200 px-3 py-1 rounded-full text-sm">
            {skill.name}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;