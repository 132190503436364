import React from 'react';
import About from './components/About';
import Certifications from './components/Certifications';
import Education from './components/Education';
import Experience from './components/Experience';
import Skills from './components/Skills';
import { useProfileData } from './hooks/useProfileData';

const App: React.FC = () => {
  const { data, loading, error } = useProfileData();

  if (loading) return <div className="flex items-center justify-center h-screen text-white">Loading...</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-red-500">Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="lg:col-span-2">
            <About data={data?.about} />
          </div>
          <div>
            <Experience data={data?.experience} />
            <div className="mt-6">
              <Skills data={data?.skills} />
            </div>
          </div>
          <div>
            <Education data={data?.education} />
            <div className="mt-6">
              <Certifications data={data?.certifications} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;