import { useState, useEffect } from 'react';
import axios from 'axios';
import { ProfileData } from '../types/ProfileTypes';

const CACHE_KEY = 'linkedinProfileData';
const CACHE_EXPIRATION = 1 * 24 * 60 * 60 * 1000; // 1 day in milliseconds

export const useProfileData = () => {
  const [data, setData] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          if (Date.now() - timestamp < CACHE_EXPIRATION) {
            setData(data);
            setLoading(false);
            return;
          }
        }

        const response = await axios.get<ProfileData>(`${process.env.REACT_APP_API_URL}/profile`);
        setData(response.data);
        
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          data: response.data,
          timestamp: Date.now()
        }));

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch profile data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};