import React from 'react';
import { About as AboutType } from '../types/ProfileTypes';

interface AboutProps {
  data: AboutType | undefined;
}

const About: React.FC<AboutProps> = ({ data }) => {
  if (!data) return null;

  return (
    <section className="bg-gray-800 shadow-lg rounded-lg p-6 mb-6">
      <h1 className="text-4xl font-bold text-white mb-2">{data.firstName} {data.lastName}</h1>
      <h2 className="text-2xl text-gray-300 mb-4">{data.headline}</h2>
      <p className="text-gray-400 mb-4">{data.summary}</p>
      <div className="text-gray-400">
        {data.email && <p className="mb-2">Email: {data.email}</p>}
        {(data.city || data.country) && (
          <p>Location: {[data.city, data.country].filter(Boolean).join(', ')}</p>
        )}
      </div>
    </section>
  );
};

export default About;