import React from 'react';
import { Certification } from '../types/ProfileTypes';

interface CertificationsProps {
  data: Certification[] | undefined;
}

const formatDate = (date: string | { year: number; month?: number; day?: number } | undefined | null): string => {
  if (!date) return 'Present';
  if (typeof date === 'string') {
    return new Date(date).toLocaleString('en-US', { month: 'short', year: 'numeric' });
  }
  const { year, month } = date;
  return `${month ? new Date(year, month - 1).toLocaleString('en-US', { month: 'short' }) : ''} ${year}`;
};

const Certifications: React.FC<CertificationsProps> = ({ data }) => {
  if (!data || data.length === 0) return null;

  return (
    <section className="bg-gray-800 shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4 text-white">Certifications</h2>
      <div className="space-y-6">
        {data.map((cert, index) => (
          <div key={index} className="border-b border-gray-700 pb-6 last:border-b-0 last:pb-0">
            <h3 className="text-xl font-semibold text-white">{cert.name}</h3>
            <p className="text-gray-300">Issuing Authority: {cert.authority}</p>
            {cert.licenseNumber && <p className="text-gray-300">License: {cert.licenseNumber}</p>}
            <p className="text-gray-300">
              {formatDate(cert.startDate)} - {formatDate(cert.endDate)}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Certifications;